import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

export enum MessageBoxType {
  alert = 'alert',
  complete = 'complete',
  info = 'info',
  warning = 'warning'
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-message-box',
  styleUrls: ['./message-box.component.scss'],
  templateUrl: './message-box.component.html'
})
export class MessageBoxComponent implements OnInit {
  @Input() type: MessageBoxType;
  @Input() messages: string[];

  constructor() {}

  @HostBinding('class') get classes(): string {
    return this.type;
  }

  ngOnInit() {}

  get icon(): string {
    let stringPaths = {
      alert: 'attention',
      complete: 'check-circle',
      info: 'info',
      warning: 'attention'
    };
    let iconName: string = stringPaths[this.type];

    return `/assets/icons/${iconName}.svg`;
  }
}
