import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export enum ControlStyle {
  Transparent = 'transparent',
  Warning = 'warning'
}

export interface Control {
  click: () => void;
  label: string;
  style?: ControlStyle;
}

export interface FormLayoutState {
  controls?: Control[];
  title?: string;
}

function createInitialState(): FormLayoutState {
  return {
    controls: [],
    title: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form-layout', resettable: true })
export class FormLayoutStore extends Store<FormLayoutState> {
  constructor() {
    super(createInitialState());
  }
}
