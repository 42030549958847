import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { FormState } from './form.model';

export let initialFundState = {
  activeSessions: 0,
  form: undefined,
  id: undefined,
  membersOnline: undefined
};

export function createInitialState(): FormState {
  return initialFundState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form', resettable: true })
export class FormStore extends Store<FormState> {
  constructor() {
    super(createInitialState());
  }
}
