import { ID } from '@datorama/akita';

import { BaseModel } from '@state/base';
import { EntityMemberCommunicationType, EntityMemberRole, EntityMemberRoleType } from '@state/entities';

export interface EntityMember {
  created_at: string;
  created_at_formatted: string;
  email: string;
  entity_id: string;
  entity_member_id: string;
  entity_member_roles: EntityMemberRole[];
  id: ID;
  invitation_state: string;
  invitation_token: string;
  name: string;
  role: string;
  role_humanized: string;
  title: string;
  user_id: string;
  user_verified: boolean;
}

export interface Member {
  id: ID;
  email: string;
  first_name: string;
  full_name: string;
  initials: string;
  last_name: string;
}

export interface ProviderContext {
  id: ID;
  display_name: string;
  logo?: string;
}

export interface Membership extends BaseModel {
  full_cpc_on_root?: boolean;
  display_label: string;
  entity_id: ID;
  initials: string;
  investment_account_id?: ID;
  logo_url: string;
  members_count: number;
  members: Member[];
  name: string;
  organization_id?: ID;
  provider: boolean;
  provider_context?: Partial<ProviderContext>;
  saas_enabled: boolean;
  service_enabled: boolean;
  total_contributed?: number;
  total_subscribed?: number;
  total_subscribed_formatted?: string;
  total_contributed_formatted?: string;
  total_aum_formatted?: number;
  total_aum?: string;
  total_invested_formatted?: number;
  total_invested?: number;
}

export interface OrganizationMembership extends BaseModel {
  entity_id: ID;
  name: string;
}

export enum MemberRoleType {
  Administrator = 'administrator',
  Limited = 'limited',
  Owner = 'owner',
  Viewer = 'viewer'
}

export interface MemberInviteParams {
  communication_types?: EntityMemberCommunicationType[];
  entity_member_roles?: EntityMemberRoleType[];
  email?: string;
  first_name?: string;
  id?: ID;
  last_name?: string;
  role?: string;
  title?: string;
}
