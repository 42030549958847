<!--
  TODO: Too many containers, doesn't need to be like this
  https://app.asana.com/0/1151173403660257/1164225481162803/f
-->
<div class="auth-wrapper">
  <div class="auth-main">
    <div class="header">
      <h2 class="auth-title">Log in</h2>
      <span *ngIf="authMessage$ | async" class="auth-message">
        {{authMessage$ | async}}
      </span>
    </div>

    <p>Log in to view and manage your funds, investments, documents, and more.</p>

    <form class="auth-form" #f="ngForm" (ngSubmit)="f.form.valid && submit()" *ngIf="mfa_token_view$ | async | none">
      <mat-form-field
        [class.mat-form-field-invalid]="isEmailInvalid"
        [hideRequiredMarker]="true"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Email</mat-label>

        <input matInput class="text-input" id="email-input" type="email" placeholder="Email" autocomplate="email"
          autocorrect="off" autocapitalize="off" spellcheck="false" required [disabled]="loginFormSubmitted$ | async"
          [(ngModel)]="sessionService.credentials.email" [ngModelOptions]="{standalone: true}" (blur)="validateEmailField()" (keydown)="resetEmailValidation()" tabindex="1">

        <div class="material-icons success auth-field-icon" [inlineSVG]="'/assets/icons/check--thin.svg'"
          *ngIf="isEmailValid"></div>

        <div
          [inlineSVG]="'/assets/icons/attention.svg'"
          *ngIf="isEmailInvalid"
          class="material-icons error"
          matTooltip="Not a valid email address format."
          matTooltipPosition="above"
        ></div>
      </mat-form-field>

      <div class="forgot-password">
        <a [routerLink]="['/forgot-password']" *ngIf="loginFormSubmitted$ | async | none" class="auth-link" tabindex="4">
          Forgot password?
        </a>
      </div>
      <mat-form-field
        [hideRequiredMarker]="true"
        appearance="outline"
        class="with-hint-link auth-last-field"
        floatLabel="always"
      >
        <mat-label>Password</mat-label>
        <input matInput class="text-input" id="password-input" type="password" placeholder="Password" autocomplete="current-password" autocorrect="off"
          autocapitalize="off" spellcheck="false" required [disabled]="loginFormSubmitted$ | async"
          [(ngModel)]="sessionService.credentials.password" [ngModelOptions]="{standalone: true}" tabindex="2">
      </mat-form-field>

      <fl-message-box *ngIf="accountLocked$ | async" [type]="MessageBoxType.alert">
        Your account has been locked due to unsuccessful login attempts.
        <a [routerLink]="['/forgot-password']">Click here</a> to reset your password
      </fl-message-box>

      <button type="submit" [disabled]="isBtnDisabled()" tabindex="3">
        Log in
      </button>

      <p class="invitation-link" *ngIf="hasTargetInvestment">Don't have an account? <a [routerLink]="['/signup']" [queryParams]="{ view: SignupView.RequestInvitation }" queryParamsHandling="merge" class="auth-link">Sign up</a></p>

      <div class="auth-loading-spinner" *ngIf="loginFormSubmitted$ | async">
        <img src="/assets/loading-spinner.gif" />
      </div>
    </form>

    <form class="login-form" #f="ngForm" (ngSubmit)="f.form.valid && submitOtp()" *ngIf="mfa_token_view$ | async">
      <p class="resend-text otp-detail">Enter the one-time passcode sent to the phone number ending in <span class="violet">**{{sessionService.phoneLastTwo}}</span>.</p>

      <input class="otp-input token-input" id="otp" name="otp" type="text" placeholder="Enter verification code" autocorrect="off"
        autocapitalize="off" spellcheck="false" required [disabled]="loginFormSubmitted$ | async" autofocus
        [(ngModel)]="sessionService.otpToken" [ngModelOptions]="{standalone: true}">

      <div class="justify-start">
        <p class="resend-text otp-detail">Haven’t received a code yet? <a (click)="submit(true)" class="resend-code" *ngIf="loginFormSubmitted$ | async | none">Resend Code.</a></p>
      </div>

      <button type="submit" [disabled]="isBtnDisabled()">
        Log in
      </button>
    </form>
  </div>
</div>
