<!--
  This class / container is used just to improve readability on mobile,
  has no semantic value. Would be great to find another solution.
-->

<div class="table-container" [class.sticky]="sticky" [class.constrained]="constrained">
  <table
    #table
    [class.compact]="compact"
    [class.unpadded]="unpadded"
    [dataSource]="dataSource"
    [matSortActive]="matSortActive"
    [matSortDirection]="matSortDirection"
    [matSortDisableClear]="true"
    mat-table
    matSort
  >
    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.def"
      [sticky]="column?.isSticky"
      [stickyEnd]="column?.isStickyEnd"
    >
      <ng-container *ngIf="column.disableSort || !column.header; else sortableHeader">
        <ng-container *ngIf="column.def === 'bulk'; else standardHeader">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              data-testid="collection-table-bulk-all-selected"
              (change)="toggleAll($event.checked)"
              [checked]="bulkAllSelected && !checkbox.disabled"
              [disabled]="isBulkSelectableCountZero"
              [indeterminate]="bulkSomeSelected"
              #checkbox="matCheckbox"
            ></mat-checkbox>
          </th>
        </ng-container>
        <ng-template #standardHeader>
          <th mat-header-cell *matHeaderCellDef>{{column.header}}</th>
        </ng-template>
      </ng-container>
      <ng-template #sortableHeader>
        <th
          [class.numeric]="column.numeric"
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
        >
          <span
            [matTooltip]="column.headerTooltip"
            matTooltipPosition="above"
          >
            {{column.header}}
          </span>
        </th>
      </ng-template>

      <td
        [attr.data-testid]="{ column, i } | function: dataTestId"
        [class.numeric]="column.numeric"
        *matCellDef="let row; let i = index"
        mat-cell
      >
        <!-- For internal component use only, designed to support bulk actions as a toggle -->
        <ng-container *ngIf="column.type === 'bulk'">
          <mat-checkbox
            (change)="updateBulkSelections($event.checked, i, row)"
            (click)="checkToggle($event, i)"
            [checked]="!!bulkSelections[i] && !checkbox?.disabled"
            [disabled]="!bulkSelectable(row)"
            #checkbox="matCheckbox"
          ></mat-checkbox>
        </ng-container>

        <!-- Action cells -->
        <ng-container *ngIf="(column | asActionColumn) as col">
          <ng-container *ngIf="(row | asStringValue: col.action.type) === ActionType.SingleAction">
            <div
              *ngIf="col.action.condition ? col.action.condition(row) : true"
              (click)="col.action.method(row, i)"
              [inlineSVG]="row | asStringValue: col.action.icon"
              [matTooltip]="col.action.tooltip"
              class="row-action"
              matTooltipPosition="above"
              role="button"
            ></div>
          </ng-container>

          <ng-container *ngIf="(row | asStringValue: col.action.type) === ActionType.SingleActionButton">
            <button
              *ngIf="col.action.condition ? col.action.condition(row) : true"
              (click)="col.action.method(row, i)"
              class="row-action"
              role="button"
            >
              <span [inlineSVG]="row | asStringValue: col.action.icon"></span>
              {{col.action.label}}
            </button>
          </ng-container>

          <ng-container *ngIf="(row | asStringValue: col.action.type) === ActionType.Clipboard">
            <span
              *ngIf="col.action.condition ? col.action.condition(row) : true"
              (cdkCopyToClipboardCopied)="col.action.onCopySuccess($event)"
              [cdkCopyToClipboard]="col.action.copyContent(row)"
              [matTooltip]="col.action.tooltip"
              class="row-action"
              data-testid="clipboard"
              inlineSVG="/assets/icons/copy.svg"
              matTooltipPosition="above"
              ngxClipboard
              role="button">
              {{col.action.label}}
            </span>
          </ng-container>

          <ng-container *ngIf="(row | asStringValue: col.action.type) === ActionType.Menu">
            <div
              class="menu-trigger"
              inlineSVG="/assets/icons/more-vert.svg"
              role="button" [matMenuTriggerFor]="menu"
              *ngIf="col.action.condition ? col.action.condition(row) : true"
            ></div>

            <mat-menu class="fl-collection-table-action-mat-menu" #menu="matMenu">
              <ng-container *ngFor="let menuItem of col.action.menu">
                <ng-container *ngIf="menuItem.condition === undefined || menuItem.condition(row)">
                  <button *ngIf="menuItem.action"
                          mat-menu-item
                          (click)="menuItem.action(row)"
                          [ngClass]="menuItem.class"
                          [disabled]="menuItem.disabled">
                    <span class="icon" *ngIf="menuItem.icon" [inlineSVG]="menuItem.icon"></span>
                    {{menuItem.label}}
                  </button>

                  <button
                    (cdkCopyToClipboardCopied)="menuItem.successCallback($event)"
                    [cdkCopyToClipboard]="menuItem.copyAction(row)"
                    *ngIf="menuItem.copyAction"
                    mat-menu-item
                    ngxClipboard>
                    {{row | asStringValue: menuItem.label}}
                  </button>

                  <a
                    mat-menu-item
                    *ngIf="menuItem.routerLink"
                    [routerLink]="menuItem.routerLink(row)">
                    <span class="icon" *ngIf="menuItem.icon" [inlineSVG]="menuItem.icon"></span>
                    {{row | asStringValue: menuItem.label}}
                  </a>

                  <hr class="divider" *ngIf="menuItem.divider" />
                </ng-container>
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>

        <!-- meta select -->
        <ng-container *ngIf="(column | asMetaSelectColumn) as col">
          <ng-container *ngIf="col.condition ? col.condition(row) : true">
            <ng-container *ngIf="row | asStringValue: col.value.main; else metaSelectPlaceholder">
              <button class="cell-select" type="button" [matMenuTriggerFor]="metaSelectMenu">
                <div>
                  {{row | asCellValue: col.value.main}}
                  <small *ngIf="row | asStringValue: col.value.meta">{{row | asStringValue: col.value.meta}}</small>
                </div>
                <div class="chevron" inlineSVG="/assets/icons/chevron-down.svg"></div>
              </button>
            </ng-container>
            <ng-template #metaSelectPlaceholder>
              <button class="cell-select" type="button" [matMenuTriggerFor]="metaSelectMenu">
                <div class="placeholder">
                  {{col.value | asCellValue: 'placeholder'}}
                </div>
                <div class="chevron" inlineSVG="/assets/icons/chevron-down.svg"></div>
              </button>
            </ng-template>
            <mat-menu class="fl-collection-table-meta-select-menu" #metaSelectMenu="matMenu">
              <ng-container *ngFor="let menuItem of col.value.menu">
                <button mat-menu-item
                  *ngIf="menuItem.condition ? menuItem.condition(row) : true"
                  (click)="menuItem.action(row)"
                  class="select-item-button mat-menu-item {{menuItem.className}}"
                  [disabled]="menuItem.disabled && menuItem.disabled(row)">
                  <span class="label">{{row | asStringValue: menuItem.label}}</span>
                  <span class="meta" *ngIf="row | asStringValue: col.value.metaMenu">{{menuItem.meta}}</span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>

        <!-- Cells that contain date values -->
        <ng-container *ngIf="(column | asDateColumn) as col">
          <ng-container *ngIf="(row | asStringValue: col.value); else noDateValue">
            {{row | asStringValue: col.value | momentDate: dateWithoutTimeCondensed : false}}
          </ng-container>
          <ng-template #noDateValue>
            –
          </ng-template>
        </ng-container>

        <!-- Cells specific to fund investor -->
        <ng-container *ngIf="(column | asFundInvestorStatusColumn) as col">
          <ng-container *ngIf="(row | asStatusValues: col.value) as statusValues">
            <span class="tag badge-{{ badge | function: toKebabCase: ' ' }}" *ngIf="statusValues.badge as badge">{{badge}}</span>

            <div class="icon-group icon-label {{label}}" *ngIf="statusValues.label as label">
              <div *ngIf="statusValues.icon" [inlineSVG]="statusValues.icon"></div>
              <span>{{label}}</span>
            </div>

            <div class="icon-group icon-progression" *ngIf="statusValues.iconSeries as iconSeries">
              <div
                [ngClass]="[icon.value.complete ? 'step-complete' : 'step-incomplete', icon.key]"
                [inlineSVG]="icon.value.icon"
                [matTooltip]="icon.value.tooltip"
                class="tooltip"
                *ngFor="let icon of iconSeries | keyvalue: nullComparison"
              ></div>
            </div>
          </ng-container>
          <ng-container *ngIf="row | asStatusValues: col.value | none">
            –
          </ng-container>
        </ng-container>

        <!-- Cells with an icon, followed by a link -->
        <div class="icon-group" *ngIf="(column | asIconLinkColumn) as col">
          <div [inlineSVG]="row | asStringValue: col.value.icon"></div>
          <a
            *ngIf="row | asBooleanValue: col.condition else iconGroupDisabled"
            [target]="col.value.target"
            [routerLink]="row | asRouterLinkValue: col.value.link"
            [attr.rel]="col.value.target === '_blank' ? 'noopener noreferrer' : null"
          >
            {{row | asCellValue: col.value.main}}
          </a>

          <ng-template #iconGroupDisabled>
            <p>
              {{row | asCellValue: col.value.main}}
            </p>
          </ng-template>
        </div>

        <!-- Cells with an icon, followed by a link, and meta data -->
        <div class="icon-group" *ngIf="(column | asIconLinkMetaColumn) as col">
          <div class="icon" [inlineSVG]="row | asStringValue: col.value.icon"></div>
          <div>
            <a
              [target]="col.value.target"
              [routerLink]="row | asRouterLinkValue: col.value.link"
              [attr.rel]="col.value.target === '_blank' ? 'noopener noreferrer' : null"
            >
              {{row | asCellValue: col.value.main}}
            </a>
            <small>{{row | asCellValue: col.value.meta}}</small>
          </div>
        </div>

        <!-- Cells with an icon -->
        <div class="icon-group" *ngIf="(column | asIconColumn) as col">
          <div class="icon" [inlineSVG]="row | asStringValue: col.value.icon"></div>
          <div>
            {{row | asCellValue: col.value.main}}
          </div>
        </div>

        <!-- Cells with an icon, followed by meta data -->
        <div class="icon-group" *ngIf="(column | asIconMetaColumn) as col">
          <div *ngIf="col.value.icon" class="icon" [inlineSVG]="row | asStringValue: col.value.icon"></div>
          <div>
            {{row | asCellValue: col.value.main}}
            <small>{{row | asCellValue: col.value.meta}}</small>
          </div>
        </div>

        <!-- Cells with an image, no meta data -->
        <div class="image-group" *ngIf="(column | asImageColumn) as col">
          <div *ngIf="row | asStringValue: col.value.image" class="image" [ngStyle]="{ 'background-image': 'url(' + (row | asStringValue: col.value.image) + ')' }"></div>
          <div *ngIf="row | asStringValue: col.value.image | none" class="default-image" inlineSVG="/assets/icons/crown.svg"></div>
          <div>{{row | asCellValue: col.value.main}}</div>
        </div>

        <!-- Cells with an image, followed by meta data -->
        <div class="image-group" *ngIf="(column | asImageMetaColumn) as col">
          <div *ngIf="row | asStringValue: col.value.image" class="image" [ngStyle]="{ 'background-image': 'url(' + (row | asStringValue: col.value.image) + ')' }"></div>
          <div *ngIf="row | asStringValue: col.value.image | none" class="default-image" inlineSVG="/assets/icons/crown.svg"></div>
          <div>
            {{row | asCellValue: col.value.main}}
            <small>{{row | asCellValue: col.value.meta}}</small>
          </div>
        </div>

        <!-- Cells with a link -->
        <a
          [attr.rel]="col.value.target === '_blank' ? 'noopener noreferrer' : null"
          [routerLink]="row | asRouterLinkValue: col.value.link"
          [target]="col.value.target"
          *ngIf="(column | asLinkColumn) as col"
        >
          {{row | asCellValue: col.value.main}}
        </a>

        <!-- Cells with a link and meta value -->
        <ng-container *ngIf="(column | asLinkMetaColumn) as col">
          <a
            [target]="col.value.target"
            [routerLink]="row | asRouterLinkValue: col.value.link"
            [attr.rel]="col.value.target === '_blank' ? 'noopener noreferrer' : null"
          >
            {{row | asCellValue: col.value.main}}
          </a>
          <small *ngIf="row | asStringValue: col.value.meta" >{{row | asStringValue: col.value.meta}}</small>
        </ng-container>

        <!-- Cells that display a list of links -->
        <ng-container *ngIf="(column | asListOfLinksColumn) as col">
          <ng-container *ngIf="row | asListOfLinksValues: col.value.main as links; else noList">
            <ul class="list-of-links">
              <li *ngFor="let link of links; index as i; last as last">
                <ng-container *ngIf="!col.value.maxShown || i <= col.value.maxShown">
                  <ng-container *ngIf="col.value.truncated; else staticLink">
                    <a
                      [target]="link.target"
                      [routerLink]="link.route"
                      [attr.rel]="link.target === '_blank' ? 'noopener noreferrer' : null"
                      [matTooltip]="link.label"
                      matTooltipPosition="above"
                    >
                      <span class="truncated-link">{{link.label}}</span>
                      <span *ngIf="link.target === '_blank'" inlineSVG="/assets/icons/link-1.svg" class="external-link-icon"></span>
                      <ng-container *ngIf="!last || col.value.maxShown && links.length > col.value.maxShown">,</ng-container>
                    </a>
                  </ng-container>
                  <ng-template #staticLink>
                    <a
                      [target]="link.target"
                      [routerLink]="link.route"
                      [attr.rel]="link.target === '_blank' ? 'noopener noreferrer' : null"
                    >
                      <span>{{link.label}}</span>
                      <span *ngIf="link.target === '_blank'" inlineSVG="/assets/icons/link-1.svg" class="external-link-icon"></span>
                      <ng-container *ngIf="!last || col.value.maxShown && links.length > col.value.maxShown">,</ng-container>
                    </a>
                  </ng-template>
                </ng-container>
              </li>
              <li class="remainder" *ngIf="col.value.maxShown && links.length > col.value.maxShown">
                +{{links.length - col.value.maxShown}} {{col.value.modelName}}
              </li>
            </ul>
          </ng-container>
          <ng-template #noList>–</ng-template>
        </ng-container>

        <!-- Cells with a Value, and meta data below -->
        <ng-container *ngIf="(column | asMetaColumn) as col">
          <div class="value-meta">
            {{row | asCellValue: col.value.main}}
            <small *ngIf="row | asStringValue: col.value.meta" >{{row | asStringValue: col.value.meta}}</small>
          </div>
        </ng-container>

        <!-- Cells that have a slide toggle -->
        <ng-container *ngIf="(column | asSlideToggleColumn) as col">
          <label
            [ngClass]="[ (row | asBooleanValue: col.value.checked) ? 'checked' : 'unchecked']"
            (click)="col.value.action(row)"
            class="slide-toggle-info"
          >
            <mat-slide-toggle
              [checked]="row | asBooleanValue: col.value.checked"
              [ngClass]="[(row | asBooleanValue: col.value.checked) ? 'checked' : 'unchecked']"
            ></mat-slide-toggle>
            <span class="status">{{row | asCellValue: col.value.label}}</span>
          </label>
        </ng-container>

        <!-- Cells that contain tags -->
        <ng-container *ngIf="(column | asTagsColumn) as col">
          <span class="tag {{ tag | function: toKebabCase: ' ' }}" *ngFor="let tag of (row | asTagsValue: col.value)">{{tag}}</span>
        </ng-container>

        <!-- Cells that contain an icon and a badge -->
        <ng-container *ngIf="(column | asIconBadgeColumn) as col">
          <fl-badge
            [color]="row | asBadgeColorValue: col.value.color"
            [icon]="row | asStringValue: col.value.icon"
            [label]="row | asCellValue: col.value.main"
          ></fl-badge>
        </ng-container>

        <!-- Cells that are under the Owner column -->
        <ng-container *ngIf="(column | asOwnerColumn) as col">
          <ng-container *ngIf="row | asStringValue: col.value.tooltip; else noToolTip">
            <div
              [matTooltip]="row | asStringValue: col.value.tooltip"
              class="owner-pending-approval"
              matTooltipPosition="above"
            >
              <span></span>
              {{row | asCellValue: col.value.main}}
            </div>
          </ng-container>
          <ng-template #noToolTip>
            {{row | asCellValue: col.value.main}}
          </ng-template>
        </ng-container>

        <!-- Cells that may contain a tooltip -->
        <ng-container *ngIf="(column | asTooltipColumn) as col">
          <ng-container *ngIf="row | asStringValue: col.value.tooltip; else noToolTip">
            <span
              [matTooltip]="row | asStringValue: col.value.tooltip"
              class="tooltip"
              [class.line-clamp-1]="column.lineClamp === 1"
              [class.line-clamp-2]="column.lineClamp === 2"
              [class.line-clamp-3]="column.lineClamp === 3"
            >
              {{row | asCellValue: col.value.main}}
            </span>
          </ng-container>
          <ng-template #noToolTip>
            <span
              [class.line-clamp-1]="column.lineClamp === 1"
              [class.line-clamp-2]="column.lineClamp === 2"
              [class.line-clamp-3]="column.lineClamp === 3"
            >
              {{row | asCellValue: col.value.main}}
            </span>
          </ng-template>
        </ng-container>

        <!-- Value with badge, will display a value if preset width a badge floated right when condition is true -->
        <ng-container *ngIf="(column | asValueBadgeColumn) as col">
          <div class="value-badge">
            {{row | asCellValue: col.value.main}}
            <span class="tag" *ngIf="row | asBooleanValue: col.value.badgeCondition">
              {{row | asStringValue: col.value.badgeLabel}}
            </span>
          </div>
        </ng-container>

        <!-- Value with tags -->
        <ng-container *ngIf="(column | asValueTagsColumn) as col">
          <div class="value-tags">
            {{row | asCellValue: col.value.main}}
            <div>
              <span class="tag {{ tag | function: toKebabCase: ' ' }}" *ngFor="let tag of (row | asTagsValue: col.value.tags)">{{tag}}</span>
            </div>
          </div>
        </ng-container>

        <!-- Default cell, simply displays a value -->
        <ng-container *ngIf="column.type | none">
          <ng-container *ngIf="column.lineClamp; else noLineClamp">
            <div
              [class.line-clamp-1]="column.lineClamp === 1"
              [class.line-clamp-2]="column.lineClamp === 2"
              [class.line-clamp-3]="column.lineClamp === 3"
            >
              {{row | asCellValue: column.value}}
            </div>
          </ng-container>
          <ng-template #noLineClamp>
            {{row | asCellValue: column.value}}
          </ng-template>
        </ng-container>
      </td>
      <ng-container *ngIf="showFooter">
        <td
          [class.numeric]="column.numeric"
          [innerHTML]="column.footerValue"
          *matFooterCellDef
          mat-footer-cell
        >
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky;"></tr>

    <ng-container *ngIf="rowSettings?.action; else standardRow">
      <tr mat-row
        (click)="rowSettings.action(row, $event)"
        [class]="row | getRowClass: bulkSelectable:selectable:rowSettings"
        *matRowDef="let row; columns: displayedColumns;"
      ></tr>
    </ng-container>
    <ng-template #standardRow>
      <tr mat-row
        [class]="row | getRowClass: bulkSelectable:selectable:rowSettings"
        *matRowDef="let row; columns: displayedColumns;"
      ></tr>
    </ng-template>

    <ng-container *ngIf="showFooter">
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: sticky;"></tr>
    </ng-container>
  </table>
</div>
