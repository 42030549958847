import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { FormLayoutState, FormLayoutStore } from './form-layout.store';

@Injectable({ providedIn: 'root' })
export class FormLayoutQuery extends Query<FormLayoutState> {
  controls$ = this.select('controls');
  title$ = this.select('title');

  constructor(protected store: FormLayoutStore) {
    super(store);
  }
}
