import { Injectable } from '@angular/core';

import { Control, FormLayoutState, FormLayoutStore } from './form-layout.store';

@Injectable({ providedIn: 'root' })
export class FormLayoutService {
  constructor(private formLayoutStore: FormLayoutStore) {}

  reset(): void {
    this.formLayoutStore.reset();
  }

  setAllAttributes({ controls, title }: FormLayoutState): void {
    this.formLayoutStore.update({ controls, title });
  }

  setTitle(title: string): void {
    this.formLayoutStore.update({ title });
  }

  setControls(controls?: Control[]): void {
    this.formLayoutStore.update({ controls });
  }
}
