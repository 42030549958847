import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';

import { Form, MemberOnline } from './form.model';
import { FormStore } from './form.store';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(private formStore: FormStore) {}

  set form(form: Form) {
    this.formStore.update({ form });
  }

  set id(id: ID) {
    this.formStore.update({ id });
  }

  set membersOnline(membersOnline: ReadonlyMap<string, MemberOnline>) {
    let activeSessions = Object.keys(membersOnline).length;
    membersOnline = { ...membersOnline };
    this.formStore.update({ activeSessions, membersOnline });
  }

  addMember({ id, info }: { id: string, info: MemberOnline }): void {
    if (this.formStore.getValue().membersOnline[id]) return;

    this.formStore.update((state) => {
      let membersOnline = { ...state.membersOnline };
      membersOnline[id] = { ...info };
      let activeSessions = Object.keys(membersOnline).length;

      return { ...state, activeSessions, membersOnline };
    });
  }

  removeMember(id: string): void {
    if (!this.formStore.getValue().membersOnline[id]) return;
    this.formStore.update((state) => {
      let membersOnline = { ...state.membersOnline };
      delete membersOnline[id];
      let activeSessions = Object.keys(membersOnline).length;

      return { ...state, activeSessions, membersOnline };
    });
  }
}
