import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { FormState } from './form.model';
import { FormStore } from './form.store';

@Injectable({ providedIn: 'root' })
export class FormQuery extends Query<FormState> {
  activeSessions$ = this.select('activeSessions');
  membersOnline$ = this.select('membersOnline');
  updatedAt$ = this.select((state) => state.form?.updated_at);

  constructor(protected store: FormStore) {
    super(store);
  }
}
