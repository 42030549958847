<div class="auth-wrapper" *ngIf="invitation$ | async as invitation">
  <section class="auth-main">
    <h1 class="auth-title">Create your account</h1>
    <p class="auth-intro">Welcome<span *ngIf="invitation?.first_name"> {{invitation?.first_name}}</span>,</p>
    <p class="auth-intro auth-linebreak" [innerHtml]="invitation?.signup_message_raw_html"></p>
    <p class="auth-intro-create-password">
      Confirm your name and create a password below in order to log in to Flow and get started.
    </p>

    <form [formGroup]="signUpForm" (ngSubmit)="onSubmit(invitation)" data-testid="signup-form" class="auth-form">
      <fieldset class="name-email-fields">
        <mat-form-field appearance="outline" [floatLabel]="'always'" [hideRequiredMarker]="true" class="name-field">
          <mat-label>Name</mat-label>
          <input
            matInput
            autocapitalize="off"
            autocorrect="off"
            autofocus
            formControlName="name"
            id="name_input"
            placeholder="Full Name"
            required
            spellcheck="false"
            type="name"
            name="name"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" [floatLabel]="'always'" [hideRequiredMarker]="true">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            id="email_input"
            name="email"
            placeholder="Email"
            type="email"
          />
        </mat-form-field>
      </fieldset>

      <div class="form-field-with-hint with-password-score">
        <mat-form-field appearance="outline" [floatLabel]="'always'" [hideRequiredMarker]="true">
          <mat-label>Create Password</mat-label>

          <input
            matInput
            id="password_input"
            type="password"
            placeholder="Create Password"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            required
            formControlName="password"
            name="password"
          />

          <div *ngIf="!signUpForm.pristine" class="password-score password-score-{{ passwordScore }}">
            <span class="password-score-badge weak-password">Weak</span>
            <span class="password-score-badge fair-password">Fair</span>
            <span class="password-score-badge good-password">Good</span>
            <span class="password-score-badge strong-password">Strong</span>
          </div>

          <mat-hint class="form-field-hint">
            We take security seriously. To protect your account please create a strong password or use a
            password generator.
          </mat-hint>
        </mat-form-field>
      </div>

      <mat-form-field class="confirm-password-input" appearance="outline" [floatLabel]="'always'" [hideRequiredMarker]="true">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          data-testid="confirm-password-input"
          type="password"
          placeholder="Confirm Password"
          autocorrect="off"
          formControlName="confirmPassword"
          autocapitalize="off"
          spellcheck="false"
          required
        />
        <mat-hint class="form-field-hint">Both passwords must match</mat-hint>
      </mat-form-field>

      <fl-message-box *ngIf="signUpForm.errors?.notSame && !signUpForm.get('confirmPassword').pristine" class="confirm-password-message-box" [type]="messageBoxType.alert">
        <span>Please check you have entered the same new password in both fields above.</span>
      </fl-message-box>

      <mat-checkbox class="terms-agreement" name="terms-agreement" formControlName="termsAgreement">
        By clicking “Create Account” below you agree to our
        <a href="{{ PRIVACY_POLICY_URL }}" target="_blank">Privacy Policy</a> and
        <a href="{{ TERMS_OF_USE_URL }}" target="_blank">Terms of Use</a>.
      </mat-checkbox>

      <button
        color="primary"
        type="submit"
        class="auth-submit-button"
        [disabled]="signUpForm.invalid || (this.signAndAcceptInvitation$ | async) !== null"
      >
        Create Account
      </button>
    </form>
  </section>
</div>

<div class="auth-wrapper" *ngIf="requestInvitationForm">
  <ng-container *ngIf="sentInvitation; else requestView">
    <section class="auth-main invitation-sent">
      <h1 class="auth-title">Invitation Sent</h1>
      <p class="auth-intro">
        If your email address exists in the system, you will receive a confirmation to complete your account
        setup.
      </p>
      <img src="/assets/compose--large.svg" alt="Graphic of paper airplane with a message being sent off" />
    </section>
  </ng-container>
  <ng-template #requestView>
    <section class="auth-main">
      <h1 class="auth-title">Confirm Invitation</h1>
      <p class="auth-intro">
        Please provide the following information in order to confirm your account exists in the system
      </p>

      <form data-testid="request-invitation-form" name="request-form" [formGroup]="requestInvitationForm" class="invitation-request-form auth-form">
        <fieldset class="name-email-fields">
          <mat-form-field appearance="outline" [floatLabel]="'always'" class="name-field">
            <mat-label>Name</mat-label>
            <input
              matInput
              autocapitalize="off"
              autocorrect="off"
              formControlName="name"
              name="name"
              placeholder="Full Name"
              required
              spellcheck="false"
              type="name"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" [floatLabel]="'always'">
            <mat-label>Email</mat-label>
            <input
              matInput
              autocapitalize="off"
              autocorrect="off"
              formControlName="email"
              name="email"
              placeholder="Email"
              required
              spellcheck="false"
              type="email"
            />
          </mat-form-field>
        </fieldset>

        <button
          (click)="sendInvitationRequest()"
          [disabled]="(requestInvitation$ | async) !== null"
          class="auth-submit-button"
          type="submit"
        >
          Submit
        </button>
      </form>
    </section>
  </ng-template>
</div>
