/*
  Form Service
  Form-related operations and state management
*/

import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import {
  ElementObjectType,
  Form,
  FormElement,
  FormSection,
  FormService as FormStateService,
  FormVariable,
  SelectableType,
  WidgetType
} from '@state/form';
import { PusherService } from '@state/pusher';
import { standardError } from '@helpers/error';
import { UserQuery } from '@state/user';

import { EditorState } from './editor.state';
import { BaseService } from './base.service';

export interface Actor {
  id: string;
  role: string;
  signature_request_id: string;
  user: any;
}

@Injectable({
  providedIn: 'root'
})
export class FormService extends BaseService {
  apiUrl: string = environment.apiUrl;
  elementSectionId;
  form: Form;
  formVariables: FormVariable[] = [];
  formVisibilityVariables: FormVariable[] = [];
  hasFormData = false;
  loadingVariables = false;
  savingForm = false;

  sender_request_id: string;

  get elementObjectTypes(): any {
    return {
      checkbox: 'boolean',
      content: 'content',
      custom: 'enumeration',
      date_picker: 'date_time',
      document: 'document',
      form: 'form',
      hidden: 'text',
      multi_select: 'enumeration',
      number_input: 'numeric',
      password: 'text',
      radio: 'enumeration',
      signature: 'signature',
      slide_toggle: 'boolean',
      text_area: 'text',
      text_input: 'text'
    };
  }

  constructor(
    private http: HttpClient,
    public editorState: EditorState,
    public formStateService: FormStateService,
    public pusherService: PusherService,
    private userQuery: UserQuery
  ) {
    super();
  }

  addElement(section: FormSection, widget_type: WidgetType, preexistingField?: any): any {
    let current_organization_id = this.form.organization_id;
    let data_type = this.elementObjectTypes[widget_type];
    let defaultRequiredWidgetType: WidgetType[] = [
      WidgetType.Custom,
      WidgetType.DatePicker,
      WidgetType.Document,
      WidgetType.MultiSelect,
      WidgetType.Password,
      WidgetType.Radio,
      WidgetType.TextArea,
      WidgetType.TextInput
    ];

    let element_object_type =
      data_type === ElementObjectType.Form
        ? ElementObjectType.Form
        : data_type === ElementObjectType.Content
        ? ElementObjectType.Content
        : ElementObjectType.FormField;
    let label = `${widget_type.replace('_', ' ')} ${section.elements.length}`;
    let params;
    let required = defaultRequiredWidgetType.includes(widget_type);
    let sectionId = section.id;

    if (!preexistingField) {
      switch (element_object_type) {
        case ElementObjectType.FormField:
          params = {
            current_organization_id,
            element_object: {
              data_type,
              label,
              required,
              widget_type
            },
            element_object_type
          };
          break;
        case ElementObjectType.Form:
          params = {
            element_object: {},
            element_object_type
          };
          label = `form ${section.elements.length}`;
          break;
        case ElementObjectType.Content:
          params = {
            current_organization_id,
            element_object: {
              raw_html: 'To be replaced',
              title: `content ${section.elements.length}`
            },
            element_object_type
          };
          break;
        default:
          break;
      }
    } else {
      params = {
        current_organization_id,
        element_object: preexistingField,
        element_object_type
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.postElement(`sections/${sectionId}/elements`, params, {
      success: (data) => {
        // Need to get added element from returned form so we can populate the sidebar
        const dataSection = data.sections.find((sectionData) => sectionData.id === sectionId);
        let dataElement;

        dataSection.elements.forEach((element) => {
          if (
            !dataElement ||
            (element.element_object.data_type === data_type && element.position > dataElement.position)
          ) {
            dataElement = element;
          }
        });

        // setting selectedItem to populate sidebar view for this field
        this.editorState.selectedItem = dataElement;
        this.editorState.selectedItem.selectableType = SelectableType.Element;
      }
    });
  }

  addSection({ section, callbacks }: { section: any; callbacks: any }): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.postSection('sections', section, callbacks);
  }

  getForm(id: ID, callbacks?: any): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.requestWithCallbacks(
      {
        data: null,
        type: 'get',
        url: `${this.BASE_URL}/v1/forms/${id}`
      },
      callbacks
    );
  }

  getFormDetails(id: ID, callbacks?: any): any {
    let url = `${this.BASE_URL}/v1/forms/${id}/details`;

    return this.http.get(url).subscribe({
      error: (error: HttpErrorResponse) => {
        standardError(error);
      },
      next: (data) => {
        this.applyCallback('success', callbacks, data);
      }
    });
  }

  // TODO: the argument list here is large enough it should be an options hash
  searchCustomVariables(
    q: string,
    data_type: string = '',
    assignable_to: string = '',
    element_data_type: string = '',
    for_visibility = false
  ): any {
    // TODO: Replace functionality so that we aren't calling searchCustomVariables on views where it's not yet
    // in use preferably, invoked from the comopnents that actually require this data
    // — maybe inserting it into Akita
    if (!this.form) return;

    if (!element_data_type && this.editorState.selectedItem.element_object) {
      element_data_type = this.editorState.selectedItem.element_object.data_type;
    }

    let organization_id = this.form.organization_id;
    let variableQuery = {
      assignable_to,
      data_type,
      element_data_type,
      q
    };

    this.loadingVariables = true;
    return this.http
      .post(`${this.BASE_URL}/v1/organizations/${organization_id}/variables/search`, variableQuery)
      .pipe(debounceTime(1000))
      .subscribe({
        next: (data: Array<FormVariable>) => {
          if (for_visibility) {
            this.formVisibilityVariables = data;
          } else {
            this.formVariables = data;
          }

          this.loadingVariables = false;
        }
      });
  }

  getFormScopeUrl(organizationId: ID, scope: string, scopeId: string): string {
    let { BASE_URL }: { BASE_URL: string } = this;
    let url: string;

    if (scope === 'organization') {
      url = `${BASE_URL}/v1/organizations/${organizationId}/forms`;
    } else if (scope === 'vehicle') {
      url = `${BASE_URL}/v1/vehicles/${scopeId}/forms`;
    }

    return url;
  }

  getForms(
    organizationId: ID,
    scope: string,
    scopeId: string,
    formIds?: Array<string>,
    callbacks?: any
  ): void {
    let url = this.getFormScopeUrl(organizationId, scope, scopeId);
    let params = new HttpParams();
    formIds.forEach((element) => {
      params = params.append('ids[]', element);
    });

    this.http.get(url, { params }).subscribe({
      error: (error: HttpErrorResponse) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (data: Form) => {
        this.applyCallback('success', callbacks, data);
      }
    });
  }

  searchForms(
    organizationId: ID,
    q: string,
    scope: string,
    scopeId: string,
    active: boolean = false,
    callbacks?: any
  ): any {
    let url = this.getFormScopeUrl(organizationId, scope, scopeId) + '/search';
    const data = { active, q };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.requestWithCallbacks({ data, type: 'post', url }, callbacks, false);
  }

  postElement(endpoint: string, elementData: any, callbacks?: any): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.post(endpoint, elementData, callbacks);
  }

  postFieldOption(sectionId: any, elementId: any, fieldOption: any, callbacks?: any): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.post(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `sections/${sectionId}/elements/${elementId}/field_options`,
      fieldOption,
      callbacks,
      false
    );
  }

  putFieldOption(sectionId: any, elementId: any, fieldOptionId: any, fieldOption: any, callbacks?: any): any {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    let url = `/sections/${sectionId}/elements/${elementId}/field_options/${fieldOptionId}`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.put(url, fieldOption, callbacks, false);
  }

  removeFieldOption(sectionId: string, elementId: string, fieldOptionId: ID, callbacks?: any): any {
    let url = `sections/${sectionId}/elements/${elementId}/field_options/${fieldOptionId}`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.delete(url, callbacks, false);
  }

  publishForm(callbacks?: any): any {
    const organization_id = this.form.organization_id;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.put(`?organization_id=${organization_id}`, { form: { active: true } }, callbacks);
  }

  putElements(
      sectionId: ID,
      elements: Array<Partial<FormElement>>,
      callbacks?: any,
      setForm = true
  ): any {
    let current_organization_id = this.form.organization_id;
    let sectionUrl = `/sections/${sectionId}/elements/update_elements`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.put(sectionUrl, { current_organization_id, elements }, callbacks, setForm);
  }

  sortElements(
    sectionId: ID,
    elements: Array<Partial<FormElement>>,
    callbacks?: any,
    setForm = true
  ): any {
    let current_organization_id = this.form.organization_id;
    let sectionUrl = `/sections/${sectionId}/elements/sort_elements`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.put(sectionUrl, { current_organization_id, elements }, callbacks, setForm, false);
  }

  putForm(form: any, callbacks?: any, setForm = true): any {
    const organization_id = this.form.organization_id;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.put(`?organization_id=${organization_id}`, { form }, callbacks, setForm);
  }

  queryFormElementById(id: ID): FormElement {
    let element: FormElement;

    this.form.sections.forEach((section) => {
      if (element) return;

      element = section.elements.find((sectionElement) => sectionElement.id === id);
    });

    return element;
  }

  queryFormElementByConfiguration(configurationKey: string, configurationValue: string): FormElement {
    let foundElement: FormElement;
    let element: FormElement;

    for (let section of this.form.sections) {
      foundElement = section.elements.find(
        (sectionElement) => sectionElement.configuration[configurationKey] === configurationValue
      );

      if (foundElement) {
        element = this.queryFormElementById(foundElement.id);
        break;
      }
    }

    return element;
  }

  queryFormSectionById(id: string): FormSection {
    // Note: DO NOT REMOVE this is dynamically called in workspace.component onMouseDown click event
    let { form } = this;
    let [selectedFormSection] = form.sections.filter((section) => section.id === id);
    return selectedFormSection;
  }

  removeElement(sectionId: ID, element: FormElement | Form | FormSection, callbacks?: any): any {
    let elementId = element.id;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.delete(`sections/${sectionId}/elements/${elementId}`, callbacks);
  }

  postSection(endpoint: string, section: any, callbacks?: any): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.post(endpoint, { section }, callbacks);
  }

  putSections(sections: any[], callbacks?: any, setForm = true): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.put('/sections/update_sections', { sections }, callbacks, setForm);
  }

  sortSections(
    sections: Array<Partial<FormSection>>,
    callbacks?: any,
    setForm = true
  ): any {
    let sectionUrl = `/sections/sort_sections`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.put(sectionUrl, {  sections }, callbacks, setForm, false);
  }

  removeSection(section: FormSection): any {
    const { id } = section;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.delete(`sections/${id}`);
  }

  delete(endpoint: string, callbacks?: any, setForm = true): any {
    const { form } = this;
    const { id } = form;

    this.sender_request_id = new Date().toUTCString();

    return this.requestWithCallbacks(
      {
        data: {},
        type: 'delete',
        url: `${this.BASE_URL}/v1/forms/${id}/${endpoint}?sender_request_id=${this.sender_request_id}`
      },
      callbacks,
      setForm
    );
  }

  post(endpoint: string, data: any, callbacks?: any, setForm = true): any {
    let { form } = this;
    let { id } = form;

    data['sender_request_id'] = this.sender_request_id = new Date().toUTCString();
    return this.requestWithCallbacks(
      {
        data,
        type: 'post',
        url: `${this.BASE_URL}/v1/forms/${id}/${endpoint}`
      },
      callbacks,
      setForm
    );
  }

  put(endpoint: string, data: any, callbacks?: any, setForm = true, savingForm = true): any {
    const { form } = this;
    const { id } = form;

    data['sender_request_id'] = this.sender_request_id = new Date().toUTCString();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.requestWithCallbacks(
      {
        data,
        type: 'put',
        url: `${this.BASE_URL}/v1/forms/${id}${endpoint}`
      },
      callbacks,
      setForm,
      savingForm
    );
  }

  requestWithCallbacks(req: any, res: any, setForm = true, savingForm = true): Subscription {
    const args = [req.url, req.data].filter((a) => a != null);

    this.savingForm = savingForm;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,prefer-spread
    return this.http[req.type].apply(this.http, args).subscribe({
      error: (error: HttpErrorResponse) => {
        this.hasFormData = false;
        this.savingForm = false;
        standardError(error);
        this.applyCallback('error', res, error);
      },
      next: (data: Form) => {
        if (setForm) {
          this.form = data;
          this.formStateService.form = data as any;
          this.formStateService.id = data.id;
        }
        this.hasFormData = true;
        this.savingForm = false;
        this.applyCallback('success', res, data);
      }
    });
  }

  getElementByFieldId(fieldId: ID): FormElement | undefined {
    let returnElement: FormElement;
    for (let section of this.form.sections) {
      for (let element of section.elements) {
        if (element.element_object.id === fieldId) {
          returnElement = element;
          break;
        }
      }
      if (returnElement) break;
    }

    return returnElement;
  }

  // TODO: Delete this after convert to user actor role in removeElementsWithDifferentRole
  removeElementsWithPageObjects(
    form: any,
    removeType: ElementObjectType = ElementObjectType.CounterSignable
  ): any {
    if (!form || !form.document) return false;

    form.sections.forEach((section) => {
      let filteredElements = [];
      section.elements.forEach((element: any) => {
        if (removeType === ElementObjectType.CounterSignable) {
          if (!element.element_object.page_object_id) {
            filteredElements.push(element);
          } else if (!form.document.page_objects[element.element_object.page_object_id].counter_signable) {
            filteredElements.push(element);
          }
        } else if (removeType === ElementObjectType.Primary) {
          if (form.document.page_objects[element.element_object.page_object_id].counter_signable) {
            filteredElements.push(element);
          }
        }
      });
      section.elements = filteredElements;
    });
  }

  removeElementsWithDifferentRole(form: any, actor: Actor): any {
    if (!form) return false;

    form.sections.forEach((section) => {
      let filteredElements = [];
      section.elements.forEach((element: any) => {
        if (element.role === actor.role) filteredElements.push(element);
      });
      section.elements = filteredElements;
    });
  }

  lockForm(callbacks?: any): any {
    return this.post(`lock`, {}, callbacks);
  }

  unlockForm(callbacks?: any): any {
    return this.post(`unlock`, {}, callbacks);
  }

  unpublishForm(callbacks?: any): any {
    const organization_id = this.form.organization_id;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.put(`?organization_id=${organization_id}`, { form: { active: false } }, callbacks);
  }

  // TODO: Need to change this to not use a getter
  get editable(): boolean {
    let user = this.userQuery.getValue().user;

    return this.form.unlocked_by_user?.id === user.id;
  }
}
